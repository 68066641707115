import { __awaiter } from "tslib";
import { deferred } from 'fast-defer';
import { createValidateStatus, isMethodIn, updateStaleRequest } from './util';
export function defaultRequestInterceptor(axios) {
    const onFulfilled = (config) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (config.cache === false) {
            return config;
        }
        // merge defaults with per request configuration
        config.cache = Object.assign(Object.assign({}, axios.defaults.cache), config.cache);
        if (!isMethodIn(config.method, config.cache.methods)) {
            return config;
        }
        const key = (config.id = axios.generateKey(config));
        // Assumes that the storage handled staled responses
        let cache = yield axios.storage.get(key);
        // Not cached, continue the request, and mark it as fetching
        emptyOrStale: if (cache.state === 'empty' || cache.state === 'stale') {
            /**
             * This checks for simultaneous access to a new key. The js event loop jumps on the
             * first await statement, so the second (asynchronous call) request may have already
             * started executing.
             */
            if (axios.waiting[key]) {
                cache = (yield axios.storage.get(key));
                break emptyOrStale;
            }
            // Create a deferred to resolve other requests for the same key when it's completed
            axios.waiting[key] = deferred();
            /**
             * Add a default reject handler to catch when the request is aborted without others
             * waiting for it.
             */
            (_a = axios.waiting[key]) === null || _a === void 0 ? void 0 : _a.catch(() => undefined);
            yield axios.storage.set(key, {
                state: 'loading',
                previous: cache.state,
                // Eslint complains a lot :)
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                data: cache.data,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                createdAt: cache.createdAt
            });
            if (cache.state === 'stale') {
                updateStaleRequest(cache, config);
            }
            config.validateStatus = createValidateStatus(config.validateStatus);
            return config;
        }
        let cachedResponse;
        if (cache.state === 'loading') {
            const deferred = axios.waiting[key];
            // Just in case, the deferred doesn't exists.
            /* istanbul ignore if 'really hard to test' */
            if (!deferred) {
                yield axios.storage.remove(key);
                return config;
            }
            try {
                cachedResponse = yield deferred;
            }
            catch (_b) {
                // The deferred is rejected when the request that we are waiting rejected cache.
                return config;
            }
        }
        else {
            cachedResponse = cache.data;
        }
        // Even though the response interceptor receives this one from here,
        // it has been configured to ignore cached responses = true
        config.adapter = () => Promise.resolve({
            config,
            data: cachedResponse.data,
            headers: cachedResponse.headers,
            status: cachedResponse.status,
            statusText: cachedResponse.statusText,
            cached: true,
            id: key
        });
        return config;
    });
    return {
        onFulfilled,
        apply: () => axios.interceptors.request.use(onFulfilled)
    };
}
