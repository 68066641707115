import { __awaiter } from "tslib";
/** Function to update all caches, from CacheProperties.update, with the new data. */
export function updateCache(storage, data, entries) {
    return __awaiter(this, void 0, void 0, function* () {
        for (const cacheKey in entries) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const value = entries[cacheKey];
            if (value === 'delete') {
                yield storage.remove(cacheKey);
                continue;
            }
            const oldValue = yield storage.get(cacheKey);
            if (oldValue.state === 'loading') {
                continue;
            }
            const newValue = yield value(oldValue, data);
            if (newValue === 'delete') {
                yield storage.remove(cacheKey);
                continue;
            }
            if (newValue === 'ignore') {
                continue;
            }
            yield storage.set(cacheKey, newValue);
        }
    });
}
