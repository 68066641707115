import { __awaiter } from "tslib";
import { testCachePredicate } from '../util/cache-predicate';
import { Header } from '../util/headers';
import { updateCache } from '../util/update-cache';
import { createCacheResponse } from './util';
export function defaultResponseInterceptor(axios) {
    /**
     * Rejects cache for an response response.
     *
     * Also update the waiting list for this key by rejecting it.
     */
    const rejectResponse = (responseId) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        // Update the cache to empty to prevent infinite loading state
        yield axios.storage.remove(responseId);
        // Reject the deferred if present
        (_a = axios.waiting[responseId]) === null || _a === void 0 ? void 0 : _a.reject(null);
        delete axios.waiting[responseId];
    });
    const onFulfilled = (response) => __awaiter(this, void 0, void 0, function* () {
        var _b, _c, _d;
        var _e;
        response.id = (_b = (_e = response.config).id) !== null && _b !== void 0 ? _b : (_e.id = axios.generateKey(response.config));
        (_c = response.cached) !== null && _c !== void 0 ? _c : (response.cached = false);
        // Response is already cached
        if (response.cached) {
            return response;
        }
        // Skip cache: either false or weird behavior
        // config.cache should always exists, at least from global config merge.
        if (!response.config.cache) {
            return Object.assign(Object.assign({}, response), { cached: false });
        }
        // Request interceptor merges defaults with per request configuration
        const cacheConfig = response.config.cache;
        const cache = yield axios.storage.get(response.id);
        if (
        // If the request interceptor had a problem
        cache.state === 'stale' ||
            cache.state === 'empty' ||
            // Should not hit here because of previous response.cached check
            cache.state === 'cached') {
            return response;
        }
        // Config told that this response should be cached.
        if (
        // For 'loading' values (post stale), this check was already run in the past.
        !cache.data &&
            !(yield testCachePredicate(response, cacheConfig.cachePredicate))) {
            yield rejectResponse(response.id);
            return response;
        }
        // avoid remnant headers from remote server to break implementation
        for (const header in Header) {
            if (!header.startsWith('XAxiosCache')) {
                continue;
            }
            delete response.headers[header];
        }
        if (cacheConfig.etag && cacheConfig.etag !== true) {
            response.headers[Header.XAxiosCacheEtag] = cacheConfig.etag;
        }
        if (cacheConfig.modifiedSince) {
            response.headers[Header.XAxiosCacheLastModified] =
                cacheConfig.modifiedSince === true
                    ? 'use-cache-timestamp'
                    : cacheConfig.modifiedSince.toUTCString();
        }
        let ttl = cacheConfig.ttl || -1; // always set from global config
        if (cacheConfig === null || cacheConfig === void 0 ? void 0 : cacheConfig.interpretHeader) {
            const expirationTime = axios.headerInterpreter(response.headers);
            // Cache should not be used
            if (expirationTime === 'dont cache') {
                yield rejectResponse(response.id);
                return response;
            }
            ttl = expirationTime === 'not enough headers' ? ttl : expirationTime;
        }
        const data = createCacheResponse(response, cache.data);
        if (typeof ttl === 'function') {
            ttl = yield ttl(response);
        }
        if (cacheConfig.staleIfError) {
            response.headers[Header.XAxiosCacheStaleIfError] = String(ttl);
        }
        // Update other entries before updating himself
        if (cacheConfig === null || cacheConfig === void 0 ? void 0 : cacheConfig.update) {
            yield updateCache(axios.storage, response, cacheConfig.update);
        }
        const newCache = {
            state: 'cached',
            ttl,
            createdAt: Date.now(),
            data
        };
        // Resolve all other requests waiting for this response
        (_d = axios.waiting[response.id]) === null || _d === void 0 ? void 0 : _d.resolve(newCache.data);
        delete axios.waiting[response.id];
        // Define this key as cache on the storage
        yield axios.storage.set(response.id, newCache);
        // Return the response with cached as false, because it was not cached at all
        return response;
    });
    const onRejected = (error) => __awaiter(this, void 0, void 0, function* () {
        var _f;
        const config = error['config'];
        if (!config || config.cache === false || !config.id) {
            throw error;
        }
        const cache = yield axios.storage.get(config.id);
        const cacheConfig = config.cache;
        if (
        // This will only not be loading if the interceptor broke
        cache.state !== 'loading' ||
            cache.previous !== 'stale') {
            yield rejectResponse(config.id);
            throw error;
        }
        if (cacheConfig === null || cacheConfig === void 0 ? void 0 : cacheConfig.staleIfError) {
            const staleIfError = typeof cacheConfig.staleIfError === 'function'
                ? yield cacheConfig.staleIfError(error.response, cache, error)
                : cacheConfig.staleIfError;
            if (staleIfError === true ||
                // staleIfError is the number of seconds that stale is allowed to be used
                (typeof staleIfError === 'number' && cache.createdAt + staleIfError > Date.now())) {
                // Resolve all other requests waiting for this response
                (_f = axios.waiting[config.id]) === null || _f === void 0 ? void 0 : _f.resolve(cache.data);
                delete axios.waiting[config.id];
                // re-mark the cache as stale
                yield axios.storage.set(config.id, {
                    state: 'stale',
                    createdAt: Date.now(),
                    data: cache.data
                });
                return {
                    cached: true,
                    config,
                    id: config.id,
                    data: cache.data.data,
                    headers: cache.data.headers,
                    status: cache.data.status,
                    statusText: cache.data.statusText
                };
            }
        }
        throw error;
    });
    return {
        onFulfilled,
        onRejected,
        apply: () => axios.interceptors.response.use(onFulfilled, onRejected)
    };
}
