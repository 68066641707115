import { __awaiter } from "tslib";
import { Header } from '../util/headers';
const storage = Symbol();
/** Returns true if the provided object was created from {@link buildStorage} function. */
export const isStorage = (obj) => !!obj && !!obj[storage];
/**
 * Builds a custom storage.
 *
 * **Note**: You can only create an custom storage with this function.
 *
 * @example
 *
 * ```js
 * const myStorage = buildStorage({
 *   find: () => {...},
 *   set: () => {...},
 *   remove: () => {...}
 * });
 *
 * const axios = setupCache(axios, { storage: myStorage });
 * ```
 */
export function buildStorage({ set, find, remove }) {
    return {
        //@ts-expect-error - we don't want to expose this
        [storage]: 1,
        set,
        remove,
        get: (key) => __awaiter(this, void 0, void 0, function* () {
            const value = yield find(key);
            if (!value) {
                return { state: 'empty' };
            }
            if (
            // Not cached or fresh value
            value.state !== 'cached' ||
                value.createdAt + value.ttl > Date.now()) {
                return value;
            }
            if (value.data.headers &&
                // Any header below allows the response to stale
                (Header.ETag in value.data.headers ||
                    Header.LastModified in value.data.headers ||
                    Header.XAxiosCacheEtag in value.data.headers ||
                    Header.XAxiosCacheStaleIfError in value.data.headers ||
                    Header.XAxiosCacheLastModified in value.data.headers)) {
                const stale = {
                    state: 'stale',
                    createdAt: value.createdAt,
                    data: value.data
                };
                yield set(key, stale);
                return stale;
            }
            yield remove(key);
            return { state: 'empty' };
        })
    };
}
