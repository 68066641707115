import { buildStorage } from './build';
/**
 * Creates a simple in-memory storage. This means that if you need to persist data between
 * page or server reloads, this will not help.
 *
 * This is the storage used by default.
 *
 * If you need to modify it's data, you can do by the `data` property.
 *
 * @example
 *
 * ```js
 * const memoryStorage = buildMemoryStorage();
 *
 * setupCache(axios, { storage: memoryStorage });
 *
 * // Simple example to force delete the request cache
 *
 * const { id } = axios.get('url');
 *
 * delete memoryStorage.data[id];
 * ```
 */
export function buildMemoryStorage() {
    const data = {};
    const storage = buildStorage({
        find: (key) => data[key],
        set: (key, value) => {
            data[key] = value;
        },
        remove: (key) => {
            delete data[key];
        }
    });
    return Object.assign(Object.assign({}, storage), { data });
}
