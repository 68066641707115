import { __awaiter } from "tslib";
/** Tests an response against a {@link CachePredicateObject}. */
export function testCachePredicate(response, predicate) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof predicate === 'function') {
            return predicate(response);
        }
        const { statusCheck, responseMatch, containsHeaders } = predicate;
        if ((statusCheck && !(yield statusCheck(response.status))) ||
            (responseMatch && !(yield responseMatch(response)))) {
            return false;
        }
        if (containsHeaders) {
            for (const header in containsHeaders) {
                const predicate = containsHeaders[header];
                if (predicate &&
                    !(yield predicate(
                    // Axios uses lowercase headers, but if for some reason it doesn't, we should
                    // still be able to match.
                    (_a = response.headers[header.toLowerCase()]) !== null && _a !== void 0 ? _a : response.headers[header]))) {
                    return false;
                }
            }
        }
        return true;
    });
}
