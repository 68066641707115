import { defaultHeaderInterpreter } from '../header/interpreter';
import { defaultRequestInterceptor } from '../interceptors/request';
import { defaultResponseInterceptor } from '../interceptors/response';
import { isStorage } from '../storage/build';
import { buildMemoryStorage } from '../storage/memory';
import { defaultKeyGenerator } from '../util/key-generator';
/**
 * Apply the caching interceptors for a already created axios instance.
 *
 * @example
 *
 * ```ts
 * import Axios from 'axios';
 * import { setupCache, AxiosCacheInstance } from 'axios-cache-interceptor';
 *
 * // instance will have our custom typings from the return of this function
 * const instance = setupCache(
 *   Axios.create({
 *     // Axios options
 *   }),
 *   {
 *     // Axios-cache-interceptor options
 *   }
 * );
 *
 * // OR
 *
 * const instance = axios.create({
 *   // Axios options
 * }) as AxiosCacheInstance;
 *
 * // As this functions returns the same axios instance but only with
 * // different typings, you can ignore the function return.
 * setupCache(instance, {
 *   // Axios-cache-interceptor options
 * });
 * ```
 *
 * @param axios The already created axios instance
 * @param config The config for the caching interceptors
 * @returns The same instance with extended typescript types.
 */
export function setupCache(axios, options = {}) {
    var _a, _b, _c, _d, _e;
    const axiosCache = axios;
    axiosCache.storage = options.storage || buildMemoryStorage();
    if (!isStorage(axiosCache.storage)) {
        throw new Error('Use buildStorage() function');
    }
    axiosCache.generateKey = options.generateKey || defaultKeyGenerator;
    axiosCache.waiting = options.waiting || {};
    axiosCache.headerInterpreter = options.headerInterpreter || defaultHeaderInterpreter;
    axiosCache.requestInterceptor =
        options.requestInterceptor || defaultRequestInterceptor(axiosCache);
    axiosCache.responseInterceptor =
        options.responseInterceptor || defaultResponseInterceptor(axiosCache);
    // CacheRequestConfig values
    axiosCache.defaults.cache = {
        ttl: (_a = options.ttl) !== null && _a !== void 0 ? _a : 1000 * 60 * 5,
        interpretHeader: (_b = options.interpretHeader) !== null && _b !== void 0 ? _b : false,
        methods: options.methods || ['get'],
        cachePredicate: options.cachePredicate || {
            statusCheck: (status) => status >= 200 && status < 400
        },
        etag: (_c = options.etag) !== null && _c !== void 0 ? _c : false,
        modifiedSince: (_d = options.modifiedSince) !== null && _d !== void 0 ? _d : false,
        staleIfError: (_e = options.staleIfError) !== null && _e !== void 0 ? _e : false,
        update: options.update || {}
    };
    // Apply interceptors
    axiosCache.requestInterceptor.apply();
    axiosCache.responseInterceptor.apply();
    return axiosCache;
}
