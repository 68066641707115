import { code } from 'object-code';
// Remove first and last '/' char, if present
const SLASHES_REGEX = /^\/|\/$/g;
export function buildKeyGenerator(hash, generator) {
    return (request) => {
        if (request.id) {
            return request.id;
        }
        // Remove trailing slashes
        request.baseURL && (request.baseURL = request.baseURL.replace(SLASHES_REGEX, ''));
        request.url && (request.url = request.url.replace(SLASHES_REGEX, ''));
        // lowercase method
        request.method && (request.method = request.method.toLowerCase());
        const result = generator(request);
        return hash ? code(result).toString() : result;
    };
}
export const defaultKeyGenerator = buildKeyGenerator(true, ({ baseURL = '', url = '', method = 'get', params, data }) => {
    return {
        url: baseURL + (baseURL && url ? '/' : '') + url,
        method,
        params: params,
        data
    };
});
